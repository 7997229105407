import styled from "styled-components";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const LoginImgs = [
  { images: "/asset/loginImg/IMG_7380.JPG", description: "나가!" },
  { images: "/asset/loginImg/IMG_1111.JPG", description: "안나가" },
  { images: "/asset/loginImg/IMG_7380.JPG", description: "나가!" },
  { images: "/asset/loginImg/IMG_6174.JPG", description: "안나가!" },
  {
    images: "/asset/loginImg/IMG_7381.jpg",
    description: "그럼 내가 가야지 <br>터벅터벅 나의 일상!",
  },
  {
    images: "/asset/loginImg/IMG_8046.JPG",
    description: "보리야 이게 머니멍?",
  },
  { images: "/asset/loginImg/IMG_0603.JPG", description: "저도 처음본다냥" },
  { images: "/asset/loginImg/IMG_6699.PNG", description: "희지가 이게 머냐옹" },
];

const getErrorMessage = (attempts: number) => {
  switch (attempts) {
    case 1:
      return "비밀번호가 틀렸습니다. 힌트는 20~";
    case 2:
      return "비밀번호가 틀렸습니다. 힌트는 2022~";
    case 3:
      return "힌트는 거짓말이였어용ㅋ 만나면 가르쳐주지~~";
    case 0:
      return "이게 멀까요~? 힌트는 숫자!";
    default:
      return "비밀번호가 틀렸습니다";
  }
};

const Login = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const nav = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % LoginImgs.length);
    }, 3000); // 3초마다 변경

    return () => clearInterval(interval); // 컴포넌트가 언마운트되면 정리
  }, []);

  const handleLogin = (password: string) => {
    if (password !== "2022032720241220") {
      setAttempts((prevAttempts) => {
        const newAttempts = prevAttempts + 1;
        if (newAttempts > 3) {
          return 1; // 다시 첫 번째 메시지로 반복
        }
        // alert(getErrorMessage(newAttempts));
        return newAttempts;
      });
    } else {
      localStorage.setItem("LoginYN", "true");
      nav(-1);
    }
  };

  const handleSubmit = () => {
    const inputElement = document.querySelector("input");
    if (inputElement) {
      handleLogin(inputElement.value);
    }
  };

  const currentImage = LoginImgs[currentIndex];

  return (
    <Container>
      <Layout />
      <MainContainer>
        <ImgContainer>
          <AllImage>
            <img src={currentImage.images} alt="" />
          </AllImage>
          <div
            dangerouslySetInnerHTML={{
              __html: currentImage.description,
            }}
          ></div>
        </ImgContainer>
        <InputWrapper>
          <Description attempt={attempts}>
            {getErrorMessage(attempts)}
          </Description>
          <ButtonBox>
            <InputBox
              type="password"
              placeholder="암호를 입력하세요"
              // onChange={(e) => handleLogin(e.target.value)}
            />
            <SubmitButton onClick={handleSubmit}>제출</SubmitButton>
          </ButtonBox>
        </InputWrapper>
      </MainContainer>
    </Container>
  );
};
export default Login;

interface AttemptProps {
  attempt: number;
}

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const SubmitButton = styled.button`
  background-color: hotpink;
  border: 1px solid pink;
  border-radius: 8px;
  padding: 8px 12px;
  color: white;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #ff69b4;
  }

  &:focus {
    outline: none;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* 중앙 정렬 */
  gap: 10px; /* 문구와 입력 필드 사이 간격 */
  width: 100%; /* 부모 요소 기준으로 중앙 정렬 */
  max-width: 300px; /* 입력 필드 최대 크기 */
  margin: 0 auto; /* 화면 중앙 배치 */
  padding-bottom: 16px;
`;

const Description = styled.div<AttemptProps>`
  font-size: 14px;
  color: ${(props) => (props.attempt > 0 ? "red" : "gray")};
  text-align: center;
`;

const InputBox = styled.input`
  border: 1px solid pink;
  border-radius: 8px;
  background-color: pink;
  padding: 8px 12px; /* 텍스트 주변 여백 */
  outline: none;
  font-size: 14px;
  width: 153px; /* 부모 요소에 맞게 크기 조절 */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);

  &:focus {
    border-color: hotpink;
    background-color: #ffe4e1; /* 포커스 시 배경색 변경 */
  }
`;

const AllImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 2rem;
  img {
    width: auto;
    height: 270px;
    object-fit: contain; /* 이미지가 컨테이너에 맞게 축소되고 잘리지 않음 */
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  /* height: 400px; */
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: calc(100vh - 56px); 기준을 화면 높이로 명확히 변경 */
  gap: 32px;
  padding: 0px 24px;
  box-sizing: border-box;
`;

const Container = styled.div`
  width: 100%;
  max-width: 720px;
  height: 100%;
  box-shadow: 0 0 20px #82828226;

  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
`;
