import styled from "styled-components";
import Layout from "../../components/Layout";
import { useEffect, useRef, useState } from "react";
import JSConfetti from "js-confetti";

interface TabButtonProps {
  isActive: boolean;
}
const View = () => {
  const [tabButton, setTabButton] = useState<string>("all");
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const openModal = (src: string) => {
    setImageSrc(src);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const confettiRef = useRef<JSConfetti | null>(null);
  useEffect(() => {
    // JSConfetti 인스턴스 생성
    const confetti = new JSConfetti();
    confettiRef.current = confetti;

    // 3초 동안 컨페티 뿌리기
    const intervalId = setInterval(() => {
      confetti.addConfetti({
        emojis: ["🌸", "🌼", "🌻"], // 꽃 이모지
        confettiRadius: 6, // 조각 크기
        confettiNumber: 50, // 조각 개수
      });
    }, 300); // 반복적으로 컨페티 생성

    // 3초 후에 중지
    setTimeout(() => {
      clearInterval(intervalId);
    }, 3000);

    return () => {
      // 컴포넌트가 언마운트될 때 정리
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Container>
      <Layout />
      <MainContainer>
        <TabContainer>
          <TabButton
            isActive={tabButton === "all"}
            onClick={() => setTabButton("all")}
          >
            1000일
          </TabButton>
          <TabButton
            isActive={tabButton === "anniversary"}
            onClick={() => setTabButton("anniversary")}
          >
            년도별
          </TabButton>
        </TabContainer>
        <ImgContainer onClick={() => openModal("/asset/IMG_7156.JPG")}>
          {tabButton === "all" ? (
            <AllImage>
              <img src="./asset/loginImg/IMG_8422.JPG" alt="" />
            </AllImage>
          ) : (
            <GridContainer>
              <GridItem>
                <img src="./asset/loginImg/IMG_1566.JPG"></img>
                <div>2022년</div>
              </GridItem>
              <GridItem>
                <img src="./asset/loginImg/IMG_3421.JPG"></img>
                <div>2023년</div>
              </GridItem>
              <GridItem>
                <img src="./asset/loginImg/IMG_7225_2.JPG"></img>
                <div>2024년</div>
              </GridItem>
            </GridContainer>
          )}
        </ImgContainer>
      </MainContainer>
      <Modal show={showModal} onClick={closeModal}>
        <CloseButton onClick={closeModal}>×</CloseButton>
        {/* <img src={imageSrc} alt="Expanded view" /> */}
        <video controls>
          {/* <source src="/asset/slideshow.webm" type="video/webm" /> */}
          <source src="/1000days.mp4" type="video/mp4" />
        </video>
      </Modal>
    </Container>
  );
};

export default View;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const Modal = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 1);
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 1000;

  img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  video {
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
  }
`;

const AllImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; /* 높이를 150%로 설정 */
  font-size: 2rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* 이미지가 컨테이너에 맞게 축소되고 잘리지 않음 */
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* 2열 레이아웃 */
  gap: 10px; /* 각 아이템 사이의 간격 */
  width: 100%;
  margin: auto;
  justify-content: center; /* 그리드 내부 항목을 수평 중앙 정렬 */
  align-items: center; /* 그리드 내부 항목을 수직 중앙 정렬 */
  justify-items: center;
`;

const GridItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  font-size: 1.2rem;
  max-width: 500px;
  max-height: 400px;
  gap: 4px;
  img {
    max-width: 400px;
    max-height: 300px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
`;

const TabButton = styled.div<TabButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? "pink" : "lightgray")};
  color: ${({ isActive }) => (isActive ? "white" : "black")};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ isActive }) => (isActive ? "pink" : "darkgray")};
  }
`;
const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 56px); /* 기준을 화면 높이로 명확히 변경 */
  gap: 32px;
  padding: 16px 24px;
  box-sizing: border-box;
`;

const Container = styled.div`
  width: 100%;
  max-width: 720px;
  height: 100%;
  box-shadow: 0 0 20px #82828226;

  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
`;
