export const isLogin = (password: string) => {
  const storedLoginYN = localStorage.getItem("LoginYN");

  if (storedLoginYN === "true" || password === "2022032720241220") {
    localStorage.setItem("LoginYN", "true");
    return true;
  } else {
    return false;
  }
};
