import { useEffect, useRef } from "react";

// 이미지 데이터 타입 정의
interface ImageData {
  img: string;
  description: string;
}

//아래와 같이 녹화를 할 경우, 화면이 보이지 않지만, images에 있는 것은 녹화가 잘되는 것을 확인할 수 있었음
const images: ImageData[] = [
  {
    description:
      "ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ",
    img: "/asset/IMG_0269.JPG",
  },
  {
    description: "사진 다골라따!",
    img: "/asset/IMG_7234.JPG",
  },
  {
    description: "희지가 아닌 비아",
    img: "/asset/IMG_7258.JPG",
  },
];

const RecordPractice = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas: HTMLCanvasElement = canvasRef.current;
    const context = canvas.getContext("2d");
    let currentIndex = 0;

    // 이미지 로드 프리로드 함수
    const preloadImages = images.map((image) => {
      const img = new Image();
      img.src = image.img;
      return new Promise<HTMLImageElement>((resolve) => {
        img.onload = () => resolve(img);
      });
    });
    // 모든 이미지 로드 완료 후 실행
    Promise.all(preloadImages).then((loadedImages: HTMLImageElement[]) => {
      const drawImage = () => {
        if (!context || !canvasRef.current) return;

        // 캔버스 초기화
        context.clearRect(0, 0, canvas.width, canvas.height);

        // 현재 이미지 가져오기
        const img: HTMLImageElement = loadedImages[currentIndex];

        // 이미지 그리기
        context.drawImage(img, 0, 0, 500, 400);

        // 말풍선 스타일 및 텍스트 그리기
        const textWidth = canvas.width; // 2% 여백
        const textHeight = 100;
        const padding = canvas.width * 0.0001; // 2% 여백
        const x = canvas.width * 0.0001; // 시작 x 좌표
        const y = canvas.height - textHeight - padding;

        context.fillStyle = "yellow";
        context.fillRect(x, y, textWidth, textHeight);

        // 말풍선 테두리
        context.lineWidth = 9; // 테두리 두께
        context.strokeStyle = "pink"; // 테두리 색상
        context.strokeRect(x, y - 5, textWidth, textHeight);
        // context.font = "12px Arial";
        // context.fillStyle = "black";
        // context.textAlign = "center";

        // const description: string = images[currentIndex].description;
        // context.fillText(
        //   description,
        //   20 + 20, // 하트와 텍스트 사이 간격 조정
        //   canvas.height - textHeight / 2
        // );

        // Cubic curves example
        // 하트 그리기
        const heartX = 23; // 하트의 x 위치 조정
        const heartY = canvas.height - textHeight / 2 - 30; // 하트의 y 위치 조정 (텍스트 위치에서 약간 위로 이동)

        const scale = 1.33; // 스케일링 팩터

        context.beginPath();
        context.moveTo(heartX, heartY);
        context.bezierCurveTo(
          heartX,
          heartY - 2.5 * scale,
          heartX - 1.3 * scale,
          heartY - 5.9 * scale,
          heartX - 4.8 * scale,
          heartY - 5.9 * scale
        );
        context.bezierCurveTo(
          heartX - 11.5 * scale,
          heartY - 5.9 * scale,
          heartX - 11.5 * scale,
          heartY - 2.5 * scale,
          heartX - 11.5 * scale,
          heartY - 2.5 * scale
        );
        context.bezierCurveTo(
          heartX - 11.5 * scale,
          heartY + 2.4 * scale,
          heartX - 9 * scale,
          heartY + 5.3 * scale,
          heartX,
          heartY + 6 * scale
        );
        context.bezierCurveTo(
          heartX + 9 * scale,
          heartY + 5.3 * scale,
          heartX + 11.5 * scale,
          heartY + 2.4 * scale,
          heartX + 11.5 * scale,
          heartY - 2.5 * scale
        );
        context.bezierCurveTo(
          heartX + 11.5 * scale,
          heartY - 2.5 * scale,
          heartX + 11.5 * scale,
          heartY - 5.9 * scale,
          heartX + 4.8 * scale,
          heartY - 5.9 * scale
        );
        context.bezierCurveTo(
          heartX + 1.3 * scale,
          heartY - 5.9 * scale,
          heartX,
          heartY - 2.5 * scale,
          heartX,
          heartY
        );
        context.fillStyle = "blue";
        context.fill();

        // 빨간색 하트 (대칭)
        const heartXRed = canvas.width - heartX; // 반대쪽 x 위치
        // const heartYRed = canvas.height - 50; //50px 밑에
        const heartYRed = canvas.height - textHeight / 2 - 30; // 하트의 y 위치 조정 (텍스트 위치에서 약간 위로 이동)

        context.beginPath();
        context.moveTo(heartXRed, heartYRed);
        context.bezierCurveTo(
          heartXRed,
          heartYRed - 2.5 * scale,
          heartXRed + 1.3 * scale,
          heartYRed - 5.9 * scale,
          heartXRed + 4.8 * scale,
          heartYRed - 5.9 * scale
        );
        context.bezierCurveTo(
          heartXRed + 11.5 * scale,
          heartYRed - 5.9 * scale,
          heartXRed + 11.5 * scale,
          heartYRed - 2.5 * scale,
          heartXRed + 11.5 * scale,
          heartYRed - 2.5 * scale
        );
        context.bezierCurveTo(
          heartXRed + 11.5 * scale,
          heartYRed + 2.4 * scale,
          heartXRed + 9 * scale,
          heartYRed + 5.3 * scale,
          heartXRed,
          heartYRed + 6 * scale
        );
        context.bezierCurveTo(
          heartXRed - 9 * scale,
          heartYRed + 5.3 * scale,
          heartXRed - 11.5 * scale,
          heartYRed + 2.4 * scale,
          heartXRed - 11.5 * scale,
          heartYRed - 2.5 * scale
        );
        context.bezierCurveTo(
          heartXRed - 11.5 * scale,
          heartYRed - 2.5 * scale,
          heartXRed - 11.5 * scale,
          heartYRed - 5.9 * scale,
          heartXRed - 4.8 * scale,
          heartYRed - 5.9 * scale
        );
        context.bezierCurveTo(
          heartXRed - 1.3 * scale,
          heartYRed - 5.9 * scale,
          heartXRed,
          heartYRed - 2.5 * scale,
          heartXRed,
          heartYRed
        );
        context.fillStyle = "red";
        context.fill();

        // 말풍선 텍스트 그리기
        context.fillStyle = "black";
        if (currentIndex === 1) {
          context.font = "14px Arial";
        } else {
          context.font = "13px Arial";
        }

        context.textAlign = "left"; // 텍스트 왼쪽 정렬

        const textX = heartX + 20; // 하트와 텍스트 사이 간격 조정 (20px)
        const textY = heartY + 2;

        // 하트와 텍스트를 같이 그리기
        context.fillText(images[currentIndex].description, textX, textY);

        // 다음 인덱스로 이동
        currentIndex = (currentIndex + 1) % images.length;

        // 다음 프레임 요청
        setTimeout(drawImage, 3000); // 10초마다 이미지 변경
      };

      // const interval = setInterval(drawImage, 10000); // 3초마다 이미지 변경
      drawImage(); // 초기 이미지 렌더링

      // return () => clearInterval(interval);
    });
  }, []);
  return (
    <div>
      <canvas
        ref={canvasRef}
        width={500}
        height={510}
        style={{ boxShadow: "0 0 20px #82828226" }}
      />
    </div>
  );
};

export default RecordPractice;
