import React from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Show from "./pages/show/Show";
import Main from "./pages/Main/Main";
import List from "./pages/List/List";
import View from "./pages/View/View";
import SlideshowRecorder from "./pages/record/SlideshowRecorder";
import RecordPractice from "./pages/record/RecordPractice";
import Login from "./pages/Login/Login";
import { isLogin } from "./service/login";
const ProtectedRoute = ({ children }: any) => {
  const isAuthenticated = isLogin(""); // 비밀번호 입력 없이 확인
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return children;
};

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Main />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/list"
        element={
          <ProtectedRoute>
            <List />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/show"
        element={
          <ProtectedRoute>
            <Show />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/view"
        element={
          <ProtectedRoute>
            <View />
          </ProtectedRoute>
        }
      ></Route>
      <Route path="/record" element={<SlideshowRecorder />}></Route>
      <Route path="/pr" element={<RecordPractice />}></Route>
    </Routes>
  );
}

export default App;
