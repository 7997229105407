/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

// 이미지 데이터 타입 정의
interface ImageData {
  img: string;
  description: string;
}

//아래와 같이 녹화를 할 경우, 화면이 보이지 않지만, images에 있는 것은 녹화가 잘되는 것을 확인할 수 있었음
const images: ImageData[] = [
  { img: "/asset/show/IMG_6475.JPG", description: "welcome!!" },
  {
    img: "/asset/show/IMG_7388.JPG",
    description: "희지와 동일이의 1000일 기념일에 오신 것을 환영합니다",
  },
  {
    img: "/asset/show/IMG_3040.JPG",
    description: "지금부터 환영행사를 시작합니다! ",
  },
  {
    img: "/asset/show/3472591445679605340_20241216094934365.JPG",
    description: "토익학원에서 처음만난 우리는",
  },
  {
    img: "/asset/show/3472591445679605084_20241216094934373.JPG",
    description: "열심히 공부를 해서 원하는 점수를 내고, 수업 조교가 되었어",
  },
  {
    img: "/asset/show/IMG_7419.JPG",
    description: "같이 공부하고 등산도 가면서",
  },
  {
    img: "/asset/show/3472495717769160540_20241216081742281.JPG",
    description: "서로에 대한 호감이 생겼고,",
  },
  {
    img: "/asset/show/3472495717200981596_20241216081742254.JPG",
    description: "우리는 사귀게 되었지",
  },
  {
    img: "/asset/show/3472495717201921628_20241216081742267.JPG",
    description: "같이 사진찍는 것도 어색했던 우리는",
  },
  {
    img: "/asset/show/3472495717236848221_20241216081742268.JPG",
    description: "사귄지 2달이 다되가서야 전신 사진을 같이 찍어보게 되었지",
  },
  {
    img: "/asset/show/3472495717258877277_20241216081742269.JPG",
    description: "수업 땡땡이 치고, 같이 피크닉 가고",
  },
  {
    img: "/asset/show/3472495717300007004_20241216081742272.JPG",
    description: "시원한 바다에서 수영하고 재밌게 놀다보니",
  },
  {
    img: "/asset/show/3472495717327886429_20241216081742273.JPG",
    description: "어느새 우리는 100일이 되었어",
  },
  {
    img: "/asset/show/3472495717342597212_20241216081742274.JPG",
    description: "우리가 같이 첫 여행을 갔던 경주!",
  },
  {
    img: "/asset/show/3472495717534015068_20241216081742274.JPG",
    description: "너무 재밌었고, 우리가 잘맞다는 사실을 알게 되었지!",
  },
  {
    img: "/asset/show/3472495717643044957_20241216081742277.JPG",
    description: "그 해 우리는 바닷가에서 봉사도 하고",
  },
  {
    img: "/asset/show/IMG_1298.JPG",
    description: "나의 졸업식을 같이 보냈지!",
  },
  {
    img: "/asset/show/IMG_1595.JPG",
    description: "순식간에 여름이 지났고, 우리는 200일을 맞이하게 되었어",
  },
  {
    img: "/asset/show/IMG_7430.JPG",
    description: "이때 우리는 알았을까? 금방 1년이 지나고 1000일이 온다는 것을",
  },
  {
    img: "/asset/show/IMG_1544.JPG",
    description: "우리의 첫크리스마스가 지나고,",
  },
  {
    img: "/asset/show/IMG_7431.PNG",
    description: "처음으로 함께 새해를 맞이했던 2023년이 왔어",
  },
  {
    img: "/asset/show/IMG_3061.JPG",
    description: "시작부터 재밌게 우리는 일본에 갔고,",
  },
  {
    img: "/asset/show/IMG_3785.JPG",
    description: "맛있는 것도 많이 먹고, 최고의 온천을 갔지!",
  },
  { img: "/asset/show/IMG_4600.JPG", description: "같이 꽃놀이가서 꽃반지도" },
  {
    img: "/asset/show/IMG_5892.JPG",
    description: "1주년 때는 우리의 반지도 맞췄지!",
  },
  {
    img: "/asset/show/IMG_8422.JPG",
    description: "희지 생일에는 거제도 가서 물놀이도 하고 재밌게 보냈지!",
  },
  {
    img: "/asset/show/IMG_7480.JPG",
    description: "여름에 희지의 졸업식에서 희지네 가족을 처음 뵙고",
  },
  {
    img: "/asset/show/IMG_7499.JPG",
    description: "처음 뵜지만 희지처럼 좋으신 분들이였고,",
  },
  {
    img: "/asset/show/IMG_7433.PNG",
    description: "아주 행복한 시간을 보낼 수 있었어",
  },
  {
    img: "/asset/show/IMG_4894.JPG",
    description: "가을에는 글램핑 가서 불멍도 같이 해보고,",
  },
  {
    img: "/asset/show/IMG_4883.JPG",
    description: "희지의 아주 맛있는 부대찌게를 맛볼 수 있었어",
  },
  {
    img: "/asset/show/IMG_6684.JPG",
    description: "우리의 두번째 크리스마스는",
  },
  {
    img: "/asset/show/IMG_3274.JPG",
    description: "눈썰매장에서 눈구경도 하고, 재밌게 눈썰매도 탔어",
  },
  {
    img: "/asset/show/IMG_7953.JPG",
    description: "제야의 종을 보며 같이 한해를 보냈고,",
  },
  {
    img: "/asset/show/IMG_3507.JPG",
    description: "2023년은 우리가 시작과 끝을 함께한 특별한 해였어",
  },
  {
    img: "/asset/show/IMG_3706.JPG",
    description: "우리의 첫 대구에서는 사귀기 전에 탔던 관람차를 탔는데",
  },
  {
    img: "/asset/show/IMG_8257.JPG",
    description: "새로운 경험이였고, 희지와 함께할 수 있었어 너무 좋았어",
  },
  {
    img: "/asset/show/IMG_9067.JPG",
    description: "희지집에 초대 받아서 맛있는 요리도 먹고",
  },
  {
    img: "/asset/show/IMG_9019.JPG",
    description: "세상에서 제일 귀여운 고양이 보리도 볼 수 있었어",
  },
  {
    img: "/asset/show/IMG_9382.JPG",
    description: "취업을 서울로 하게 되어 나홀로 서울에 오게 되었어",
  },
  {
    img: "/asset/show/IMG_4361.JPG",
    description: "서울에서 새로운 곳들도 여러군데 가봤어.",
  },
  {
    img: "/asset/show/IMG_5868.JPG",
    description: "우리가 잠시 떨어져있었던 순간이 있었지만",
  },
  {
    img: "/asset/show/IMG_0608.JPG",
    description: "그 순간으로 인해 우리가 얼마나 소중한 관계인지 깨닫게 되었어",
  },
  {
    img: "/asset/show/IMG_0876.JPG",
    description: "우리의 900일에는 같이 케이크도 만들며",
  },
  { img: "/asset/show/IMG_0903.JPG", description: "우리의 기념일을 축하했어" },
  {
    img: "/asset/show/IMG_6668.JPG",
    description: "서울에서 같이 찜질방도 가보고",
  },
  {
    img: "/asset/show/IMG_6835.JPG",
    description: "희동 첫 참치도 서울에서 먹었지!",
  },
  { img: "/asset/show/IMG_3100.JPG", description: "새해 첫 눈이 오는날" },
  {
    img: "/asset/show/IMG_3054.JPG",
    description: "희지와 눈사람에 진심인 사람들이 되어 눈사람을 만들었지",
  },
  {
    img: "/asset/show/IMG_2951.JPG",
    description: "눈사람을 만들며 너무 행복한 순간이였고,",
  },
  {
    img: "/asset/show/IMG_3078.JPG",
    description: "첫 눈과 첫 눈사람을 희지와 함께할 수 있어서 너무 좋았어",
  },
  {
    img: "/asset/show/IMG_3602.JPG",
    description: "보리비아팀으로 액티비티도 하고",
  },
  {
    img: "/asset/show/IMG_3599.JPG",
    description: "북극곰 수영대회도 참여하면서",
  },
  {
    img: "/asset/show/IMG_9559.JPG",
    description: "희지와 있으면 무엇이든 다 재밌고, 행복하다는 생각이 들었어",
  },
  { img: "/asset/show/IMG_1566.JPG", description: "시간이 지남에 따라" },
  {
    img: "/asset/show/IMG_3421.JPG",
    description: "우리의 겉모습은 바뀔 수 있으나",
  },
  {
    img: "/asset/show/IMG_7225_2.JPG",
    description: "우리를 생각하는 마음은 변하지 않아",
  },
  {
    img: "/asset/show/3472495717760512349_20241216223821988.JPG",
    description: "희지와 함께 하는 모든 순간이 다 재밌었고,",
  },
  {
    img: "/asset/show/IMG_3791.JPG",
    description: "행복으로 가득찬 순간이였어",
  },
  { img: "/asset/show/IMG_7123.JPG", description: "앞으로도 나와 함께 해줘" },
  {
    img: "/asset/show/IMG_6835.JPG",
    description: "희지가 좋아하는 참치 평생사줄게! ",
  },
  {
    img: "/asset/show/IMG_6083.JPG",
    description: "희지와 함께 봄에는 벚꽃을 보고",
  },
  { img: "/asset/show/IMG_0838.JPG", description: "여름에는 물놀이를 가고" },
  { img: "/asset/show/IMG_0299.JPG", description: "가을에는 단풍 구경을 하고" },
  {
    img: "/asset/show/IMG_2979_2.JPG",
    description: "겨울에는 눈사람을 만들며 희지와 함께 보내고 싶어",
  },
  {
    img: "/asset/show/IMG_6998.JPG",
    description: "나와 함께 1000일 이라는 시간을 함께해서 너무 고마워",
  },
  {
    img: "/asset/show/IMG_7911.JPG",
    description: "앞으로 많은 것들이 변하겠지만,",
  },
  {
    img: "/asset/show/IMG_7915.JPG",
    description: "내 사랑은 자로 잰듯이 반듯해, 한번도 틀리지 않아",
  },
  {
    img: "/asset/show/IMG_7239.JPG",
    description: "실처럼 가늘 때도 한 번도 엉키지 않아",
  },
  {
    img: "/asset/show/IMG_6376.JPG",
    description: "사랑하는 나의 희지야! 우리의 1000일 축하해!",
  },
  {
    img: "/asset/show/IMG_3217.JPG",
    description: "우리의 1000일을 축하하며 희지만의 사나희 동일이가",
  },
];

const SlideshowRecoder = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null); // Explicitly specify type
  const recordedChunks = useRef<Blob[]>([]);
  const [isRecording, setIsRecording] = useState(false);

  const audio = audioRef.current;
  const startRecording = () => {
    console.log("start");
    if (!canvasRef.current) return;
    console.log("start2");
    console.log("start3");

    const stream = canvasRef.current.captureStream(30); // FPS 설정
    const mediaRecorder = new MediaRecorder(stream);

    const canvas: HTMLCanvasElement = canvasRef.current;
    const context = canvas.getContext("2d");
    let currentIndex = 0;

    // 이미지 로드 프리로드 함수
    const preloadImages = images.map((image) => {
      const img = new Image();
      img.src = image.img;
      return new Promise<HTMLImageElement>((resolve) => {
        img.onload = () => resolve(img);
      });
    });

    // 모든 이미지 로드 완료 후 실행
    Promise.all(preloadImages).then((loadedImages: HTMLImageElement[]) => {
      const stream: MediaStream = canvas.captureStream(30); // FPS 설정

      if (audio && audio instanceof HTMLAudioElement) {
        // 오디오 스트림 생성
        const audioContext = new AudioContext();
        const source = audioContext.createMediaElementSource(audio);
        const destination = audioContext.createMediaStreamDestination();
        source.connect(destination);
        source.connect(audioContext.destination); // 스피커 출력 유지
        const audioStream: MediaStream = destination.stream;

        // 비디오와 오디오 스트림 결합
        const combinedStream = new MediaStream([
          ...stream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);

        const mediaRecorder = new MediaRecorder(combinedStream);

        // 슬라이드쇼 애니메이션
        const drawImage = () => {
          if (!context || !canvasRef.current) return;

          // 캔버스 초기화
          context.clearRect(0, 0, canvas.width, canvas.height);

          // 현재 이미지 가져오기
          const img: HTMLImageElement = loadedImages[currentIndex];

          // 이미지 그리기
          context.drawImage(img, 0, 0, 500, 400);

          // 말풍선 스타일 및 텍스트 그리기
          const textWidth = canvas.width * 0.98; // 2% 여백
          const textHeight = 100;
          const padding = canvas.width * 0.01; // 2% 여백
          const x = canvas.width * 0.01; // 시작 x 좌표
          const y = canvas.height - textHeight - padding;
          context.fillStyle = "white";
          context.fillRect(x, y, textWidth, textHeight);

          // 말풍선 테두리
          context.lineWidth = 9; // 테두리 두께
          context.strokeStyle = "pink"; // 테두리 색상
          context.strokeRect(x, y - 4, textWidth, textHeight);

          context.font = "12px Arial";
          context.fillStyle = "black";
          context.textAlign = "center";

          // Cubic curves example
          // 하트 그리기
          const heartX = 23; // 하트의 x 위치 조정
          const heartY = canvas.height - textHeight / 2 - 30; // 하트의 y 위치 조정 (텍스트 위치에서 약간 위로 이동)

          const scale = 1.33; // 스케일링 팩터

          context.beginPath();
          context.moveTo(heartX, heartY);
          context.bezierCurveTo(
            heartX,
            heartY - 2.5 * scale,
            heartX - 1.3 * scale,
            heartY - 5.9 * scale,
            heartX - 4.8 * scale,
            heartY - 5.9 * scale
          );
          context.bezierCurveTo(
            heartX - 11.5 * scale,
            heartY - 5.9 * scale,
            heartX - 11.5 * scale,
            heartY - 2.5 * scale,
            heartX - 11.5 * scale,
            heartY - 2.5 * scale
          );
          context.bezierCurveTo(
            heartX - 11.5 * scale,
            heartY + 2.4 * scale,
            heartX - 9 * scale,
            heartY + 5.3 * scale,
            heartX,
            heartY + 6 * scale
          );
          context.bezierCurveTo(
            heartX + 9 * scale,
            heartY + 5.3 * scale,
            heartX + 11.5 * scale,
            heartY + 2.4 * scale,
            heartX + 11.5 * scale,
            heartY - 2.5 * scale
          );
          context.bezierCurveTo(
            heartX + 11.5 * scale,
            heartY - 2.5 * scale,
            heartX + 11.5 * scale,
            heartY - 5.9 * scale,
            heartX + 4.8 * scale,
            heartY - 5.9 * scale
          );
          context.bezierCurveTo(
            heartX + 1.3 * scale,
            heartY - 5.9 * scale,
            heartX,
            heartY - 2.5 * scale,
            heartX,
            heartY
          );
          context.fillStyle = "blue";
          context.fill();

          // 빨간색 하트 (대칭)
          const heartXRed = canvas.width - heartX; // 반대쪽 x 위치
          // const heartYRed = canvas.height - 50; //50px 밑에
          const heartYRed = canvas.height - textHeight / 2 - 30; // 하트의 y 위치 조정 (텍스트 위치에서 약간 위로 이동)

          context.beginPath();
          context.moveTo(heartXRed, heartYRed);
          context.bezierCurveTo(
            heartXRed,
            heartYRed - 2.5 * scale,
            heartXRed + 1.3 * scale,
            heartYRed - 5.9 * scale,
            heartXRed + 4.8 * scale,
            heartYRed - 5.9 * scale
          );
          context.bezierCurveTo(
            heartXRed + 11.5 * scale,
            heartYRed - 5.9 * scale,
            heartXRed + 11.5 * scale,
            heartYRed - 2.5 * scale,
            heartXRed + 11.5 * scale,
            heartYRed - 2.5 * scale
          );
          context.bezierCurveTo(
            heartXRed + 11.5 * scale,
            heartYRed + 2.4 * scale,
            heartXRed + 9 * scale,
            heartYRed + 5.3 * scale,
            heartXRed,
            heartYRed + 6 * scale
          );
          context.bezierCurveTo(
            heartXRed - 9 * scale,
            heartYRed + 5.3 * scale,
            heartXRed - 11.5 * scale,
            heartYRed + 2.4 * scale,
            heartXRed - 11.5 * scale,
            heartYRed - 2.5 * scale
          );
          context.bezierCurveTo(
            heartXRed - 11.5 * scale,
            heartYRed - 2.5 * scale,
            heartXRed - 11.5 * scale,
            heartYRed - 5.9 * scale,
            heartXRed - 4.8 * scale,
            heartYRed - 5.9 * scale
          );
          context.bezierCurveTo(
            heartXRed - 1.3 * scale,
            heartYRed - 5.9 * scale,
            heartXRed,
            heartYRed - 2.5 * scale,
            heartXRed,
            heartYRed
          );
          context.fillStyle = "red";
          context.fill();

          // 말풍선 텍스트 그리기
          context.fillStyle = "black";
          if (currentIndex === 1) {
            context.font = "14px Arial";
          } else {
            context.font = "13px Arial";
          }

          context.textAlign = "left"; // 텍스트 왼쪽 정렬

          const textX = heartX + 20; // 하트와 텍스트 사이 간격 조정 (20px)
          const textY = heartY + 2;

          // 하트와 텍스트를 같이 그리기
          context.fillText(images[currentIndex].description, textX, textY);

          // 다음 인덱스로 이동
          currentIndex = (currentIndex + 1) % images.length;

          if (currentIndex === 0) {
            // 마지막 이미지에서 2초 뒤 녹화 중지
            setTimeout(() => {
              stopRecording();
            }, 4000);
          } else {
            // 다음 프레임 요청
            setTimeout(drawImage, 3000); // 3초마다 이미지 변경
          }
        };

        // 초기 이미지 렌더링 및 슬라이드쇼 시작
        drawImage();

        // 오디오 재생 및 녹화 시작
        audio.play();

        if (audioRef.current) {
          audioRef.current.onended = () => {
            // 1 second delay before starting the next song
            // setTimeout(() => {
            //   if (audioRef.current) {
            //     audioRef.current.src = "/3333.m4a"; // New audio file
            //     audioRef.current.play();
            //   } else {
            //     console.warn("audioRef.current is null");
            //   }
            // }, 1000); // 1 second delay before starting the next audio
          };
        }

        mediaRecorder.ondataavailable = (e: BlobEvent) => {
          const blob = new Blob([e.data], { type: "video/webm" });
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "recording.webm";
          a.click();
          URL.revokeObjectURL(url);
        };

        // 슬라이드쇼 시작과 동시에 녹화 시작
        mediaRecorder.start();
        mediaRecorderRef.current = mediaRecorder;
        setIsRecording(true);
      }
    });
  };

  const stopRecording = () => {
    if (audio && audio instanceof HTMLAudioElement) {
      audio.pause(); // 오디오 중지
      audio.currentTime = 0; // 오디오 재생 위치 초기화
    }
    mediaRecorderRef.current?.stop();
    setIsRecording(false);
  };

  // useEffect(() => {
  //   if (!canvasRef.current) return;

  //   const ctx = canvasRef.current.getContext("2d");
  //   let currentIndex = 0;

  //   const drawImage = () => {
  //     if (!ctx || !canvasRef.current) return;
  //     const img = new Image();
  //     img.src = images[currentIndex].img;
  //     img.onload = () => {
  //       ctx.clearRect(
  //         0,
  //         0,
  //         canvasRef.current!.width,
  //         canvasRef.current!.height
  //       );
  //       ctx.drawImage(img, 0, 0, 360, 400);

  //       // 말풍선 스타일
  //       const textWidth = 400;
  //       const padding = canvasRef.current!.width * 0.02; // 2% 여백
  //       const textHeight = 200;

  //       // const x = canvasRef.current!.width / 2;
  //       const y = canvasRef.current!.height - 73;

  //       ctx.fillStyle = "yellow";
  //       ctx.fillRect(0, y, textWidth + padding, textHeight);
  //       ctx.textAlign = "center";

  //       // 텍스트 설정
  //       const description = images[currentIndex].description;
  //       ctx.font = "12px Arial"; // 텍스트 폰트 및 크기
  //       ctx.fillStyle = "black"; // 텍스트 색상
  //       ctx.textAlign = "center"; // 텍스트 정렬

  //       // 텍스트 그리기 (캔버스 하단 중앙)
  //       ctx.fillText(
  //         description,
  //         canvasRef.current!.width / 2,
  //         canvasRef.current!.height - 50
  //       );
  //     };
  //     currentIndex = (currentIndex + 1) % images.length;

  //     // 말풍선 스타일
  //     const textWidth = ctx.measureText(images[currentIndex].description).width;
  //     const padding = 20;
  //     const textHeight = 50;

  //     const x = canvasRef.current.width / 2 - textWidth / 2 - padding / 2;
  //     const y = canvasRef.current.height - textHeight - 60;

  //     ctx.fillStyle = "yellow";
  //     ctx.fillRect(x - padding / 2, y, textWidth + padding, textHeight);

  //     ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  //     ctx.fillStyle = "blue";
  //     ctx.fillRect(10, 10, 50, 50);

  //     ctx.strokeStyle = "black";
  //     ctx.lineWidth = 2;
  //     ctx.fillRect(x - padding / 2, y, textWidth + padding, textHeight);

  //     ctx.fillStyle = "black";
  //     ctx.textAlign = "center";

  //     ctx.fillText(
  //       images[currentIndex].description,
  //       canvasRef.current.width / 2,
  //       canvasRef.current.height - 35
  //     );
  //   };

  //   const interval = setInterval(drawImage, 10000); // 3초마다 이미지 변경
  //   drawImage(); // 초기 이미지 렌더링

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div>
      <canvas
        ref={canvasRef}
        width={500}
        height={510}
        style={{ boxShadow: "0 0 20px #82828226" }}
      />
      <audio ref={audioRef} src="/asset/welcome.mp3"></audio>
      {/* <audio ref={audioRef} src="/333.m4a" loop></audio> */}
      <button onClick={isRecording ? stopRecording : startRecording}>
        {isRecording ? "Stop Recording" : "Start Recording"}
      </button>
    </div>
  );
};

export default SlideshowRecoder;

// // 모든 이미지 로드 완료 후 실행
// Promise.all(preloadImages).then((loadedImages: HTMLImageElement[]) => {
//   // 캔버스 스트림 생성

//   if (audio && audio instanceof HTMLAudioElement) {
//     // 오디오 스트림 생성
//     let audioStream: MediaStream;

//     // Web Audio API로 오디오 스트림 생성
//     const audioContext = new AudioContext();
//     const source = audioContext.createMediaElementSource(audio);
//     const destination = audioContext.createMediaStreamDestination();
//     source.connect(destination);
//     source.connect(audioContext.destination); // 스피커 출력 유지
//     audioStream = destination.stream;

//     // 비디오와 오디오 스트림 결합
//     const combinedStream = new MediaStream([
//       ...stream.getVideoTracks(),
//       ...audioStream.getAudioTracks(),
//     ]);

//     // MediaRecorder 생성
//     const mediaRecorder = new MediaRecorder(combinedStream);

//     const canvas = canvasRef.current;

//     if (canvas) {
//       const context = canvas.getContext("2d");
//       if (context) {
//         audio.play();

//         // // 음악이 재생되는 동안 Canvas에서 그리기 작업을 수행
//         // const draw = () => {
//         //   context.clearRect(0, 0, canvas.width, canvas.height);
//         //   context.fillStyle = "blue";
//         //   context.fillRect(10, 10, 50, 50);

//         //   // 음악의 시간에 따라 그리는 내용 조정 가능
//         //   requestAnimationFrame(draw);
//         // };

//         // draw();
//         const ctx = context;

//         const drawImage = () => {
//           if (!ctx || !canvasRef.current) {
//             return;
//           }

//           // 캔버스 초기화
//           ctx.clearRect(
//             0,
//             0,
//             canvasRef.current.width,
//             canvasRef.current.height
//           );

//           // 이미지 로드
//           const img = new Image();
//           img.src = images[currentIndex].img;
//           img.onload = () => {
//             // 이미지 그리기
//             ctx.drawImage(img, 0, 0, 360, 400);

//             // 말풍선 스타일
//             const textWidth = 400;
//             const padding = canvasRef.current!.width * 0.02; // 2% 여백
//             const textHeight = 200;

//             // const x = canvasRef.current!.width / 2;
//             const y = canvasRef.current!.height - 73;

//             ctx.fillStyle = "yellow";
//             ctx.fillRect(0, y, textWidth + padding, textHeight);
//             ctx.textAlign = "center";

//             // 텍스트 설정
//             const description = images[currentIndex].description;
//             ctx.font = "12px Arial"; // 텍스트 폰트 및 크기
//             ctx.fillStyle = "black"; // 텍스트 색상
//             ctx.textAlign = "center"; // 텍스트 정렬

//             // 텍스트 그리기 (캔버스 하단 중앙)
//             ctx.fillText(
//               description,
//               canvasRef.current!.width / 2,
//               canvasRef.current!.height - 50
//             );
//           };

//           // 다음 인덱스로 이동
//           currentIndex = (currentIndex + 1) % images.length;

//           // 다음 프레임 요청
//           setTimeout(drawImage, 10000); // 10초마다 이미지 변경
//         };

//         // 초기 이미지 렌더링
//         drawImage();
//       }
//     }

//     // 녹화 데이터 처리 (예: Blob 생성 및 다운로드)
//     mediaRecorder.ondataavailable = (e) => {
//       const blob = new Blob([e.data], { type: "video/webm" });
//       const url = URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = "recording.webm";
//       a.click();
//       URL.revokeObjectURL(url);
//     };

//     mediaRecorder.start();
//     mediaRecorderRef.current = mediaRecorder;
//     setIsRecording(true);
//   }
// });

// // 슬라이드 데이터 타입
// type Slide = {
//   id: number;
//   image: string;
//   alt: string;
// };

// const slides: Slide[] = [
//   { id: 1, image: "/logo192.png", alt: "Slide 1" },
//   { id: 2, image: "/asset/horizontal-logo.png", alt: "Slide 2" },
//   { id: 3, image: "/asset/vertical-logo.png", alt: "Slide 2" },
// ];

// interface SlideWrapperProps {
//   currentSlide: number;
// }

// const SlideshowRecorder = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   const [isPlaying, setIsPlaying] = useState(true); // 슬라이드 재생 상태

//   useEffect(() => {
//     if (!isPlaying) return; // 재생 중이 아니면 실행하지 않음

//     const interval = setInterval(() => {
//       setCurrentSlide((prev) => {
//         if (prev + 1 >= slides.length) {
//           setIsPlaying(false); // 모든 이미지가 다 보여지면 재생 멈춤
//           return prev; // 마지막 이미지를 유지
//         }
//         return prev + 1;
//       });
//     }, 5000); // 3초 간격

//     return () => clearInterval(interval);
//   }, [isPlaying]);

//   // // 자동 전환 로직
//   // useEffect(() => {
//   //   const interval = setInterval(() => {
//   //     setCurrentSlide((prev) => (prev + 1) % slides.length);
//   //   }, 3000); // 3초 간격
//   //   return () => clearInterval(interval);
//   // }, []);

//   const canvasRef = useRef<HTMLCanvasElement>(null);
//   const mediaRecorderRef = useRef<MediaRecorder | null>(null);
//   const recordedChunks = useRef<Blob[]>([]);
//   const [isRecording, setIsRecording] = useState(false);

//   const startRecording = () => {
//     if (!canvasRef.current) return;

//     const stream = canvasRef.current.captureStream(30); // FPS 설정
//     const mediaRecorder = new MediaRecorder(stream);

//     mediaRecorder.ondataavailable = (event) => {
//       if (event.data.size > 0) {
//         recordedChunks.current.push(event.data);
//       }
//     };

//     mediaRecorder.onstop = () => {
//       const blob = new Blob(recordedChunks.current, { type: "video/webm" });
//       const url = URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = "slideshow.webm";
//       a.click();
//       URL.revokeObjectURL(url);
//       recordedChunks.current = [];
//     };

//     mediaRecorder.start();
//     mediaRecorderRef.current = mediaRecorder;
//     setIsRecording(true);
//   };

//   const stopRecording = () => {
//     mediaRecorderRef.current?.stop();
//     setIsRecording(false);
//   };

//   useEffect(() => {
//     if (!canvasRef.current) return;
//   }, []);

//   return (
//     <Container>
//       <div>recorder</div>
//       <div>
//         <button
//           onClick={() => {
//             console.log("Button clicked");
//             isRecording ? stopRecording() : startRecording();
//           }}
//         >
//           {isRecording ? "Stop Recording" : "Start Recording"}
//         </button>
//       </div>
//       <SlideWrapper currentSlide={currentSlide}>
//         {slides.map((slide) => (
//           <SlideImg key={slide.id}>
//             <img src={slide.image} alt={slide.alt} />
//           </SlideImg>
//         ))}
//       </SlideWrapper>
//     </Container>
//   );
// };

// export default SlideshowRecorder;

// // 스타일 컴포넌트
// const Container = styled.div`
//   position: relative;
//   width: 100%;
//   max-width: 720px;
//   height: 100%;
//   overflow: hidden;
//   margin: auto;
//   box-shadow: 0 0 20px #82828226;
// `;

// const SlideWrapper = styled.div<SlideWrapperProps>`
//   display: flex;
//   transform: ${({ currentSlide }) => `translateX(-${currentSlide * 100}%)`};

//   transition: transform 0.5s ease-in-out;
//   margin: 0 auto;
// `;

// const SlideImg = styled.div`
//   min-width: 100%;
//   height: 100%;
//   display: flex; /* Flexbox 사용 */
//   justify-content: center; /* 가로 가운데 정렬 */
//   align-items: center; /* 세로 가운데 정렬 */

//   img {
//     max-width: 300px; /* 부모 컨테이너의 너비를 초과하지 않도록 설정 */
//     max-height: auto; /* 부모 컨테이너의 높이를 초과하지 않도록 설정 */
//     object-fit: contain; /* 이미지 비율을 유지하며 컨테이너에 맞게 조정 */
//   }

// `;
